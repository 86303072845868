import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import convertToK from '../../utils/convertToK';
import formattedMoney from '../../utils/formatMoney';
import Modal from '../Modal';
import RedirectPopup from './RedirectPopup';
import lazadaApi from '../../api/lazadaApi';
import tiktokApi from '../../api/tiktokApi';
import JoinGroup from '../Modal/popup/JoinGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoins, faWarning } from '@fortawesome/free-solid-svg-icons';
import shopeApi from '../../api/shopeeApi';
import EarnCommission from '../../pages/Merchant/Shopee/EarnCommission';

const clickAffLink = (url) => {
    setTimeout(() => {
        window.open(url, '_blank');
    }, 100);
    // window.location.href = url;
};

function ProductCardMain({
    productInfo,
    isLoading,
    platform,
    onlyInfo,
    buyBtnTextColor,
    buyBtnBgColor,
    cashBackTextColor,
}) {
    const { isLogined } = useSelector((state) => state.auth);
    const navigate = useNavigate();
    const {
        productName,
        shopName,
        price,
        commission,
        imageUrl,
        sales,
        productLink,
    } = productInfo;
    const [onGetLink, setOnGetLink] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [openModalEarn, setOpenModalEarn] = useState(false);
    // const affIdList = [
    //     {
    //         sub_id: userInfo.userId,
    //         aff_id: 17353950011,
    //     },
    // ];
    // const getLinkAff = async () => {
    //     if (!isLogined) return navigate(`/login?redirect=${platform}`);
    //     if (platform === 'shopee') {
    //         const linkAff = linkShopeeAffGenerator(productLink, affIdList);
    //         if (linkAff) {
    //             setOnCopyLink(false);
    //             setLink(linkAff);
    //         }
    //         return;
    //     }
    //     setOnCopyLink(true);
    //     try {
    //         if (platform === 'lazada') {
    //             const { itemId, skuId, offerId } = productInfo;
    //             try {
    //                 const { data } = await lazadaApi.getLinkAff(
    //                     itemId,
    //                     skuId,
    //                     offerId
    //                 );
    //                 const linkAff = data.linkAff.shortLink;
    //                 setLink(linkAff);
    //             } catch (error) {
    //                 console.log(error);
    //             }
    //         }
    //     } catch (error) {
    //         console.log(error);
    //     }
    //     setOnCopyLink(false);
    // };
    // let timeOutId;
    // const copyLinkToClipboard = (value) => {
    //     if (!value) return;
    //     clearTimeout(timeOutId);
    //     copy(value);
    //     setCopied(true);
    //     timeOutId = setTimeout(() => {
    //         setCopied(false);
    //     }, 2000);
    // };

    const handleSubmit = async () => {
        if (!isLogined) return navigate(`/login?redirect=${platform}`);
        setOnGetLink(true);
        if (platform === 'shopee') {
            try {
                const { data } = await shopeApi.getAffLinkExtension(
                    productLink
                );
                console.log(data);
                const linkAff = data.data.batchCustomLink[0].longLink;
                // const vecelUrl =
                //     'https://longhousee-shopeexu.vercel.app/redirect?url=';
                // const finalUrl = vecelUrl + linkAff;
                // console.log(finalUrl);
                setOnGetLink(false);
                return clickAffLink(linkAff);
            } catch (error) {
                console.log(error);
            }
        }

        try {
            setOnGetLink(true);
            if (platform === 'lazada') {
                const { itemId, skuId, offerId } = productInfo;
                try {
                    const { data } = await lazadaApi.getLinkAff(
                        itemId,
                        skuId,
                        offerId
                    );
                    setOnGetLink(false);
                    const linkAff = data.linkAff.shortLink;
                    clickAffLink(linkAff);
                } catch (error) {
                    console.log(error);
                }
            }

            if (platform === 'tiktok') {
                const { itemId } = productInfo;
                try {
                    const { data } = await tiktokApi.addToShowCase(itemId);
                    setOnGetLink(false);
                    const linkAff = `https://tiktok.com/${data.showCase[0].tiktokshopId}`;
                    window.location.href = linkAff;
                } catch (error) {
                    console.log(error);
                }
            }
        } catch (error) {
            console.log(error);
            setOnGetLink(false);
        }
        setOnGetLink(false);
    };

    if (isLoading)
        return (
            <div className="flex gap-1 w-full justify-between">
                <div className="w-1/2 aspect-square rounded-lg bg-slate-300 animate-hightLight"></div>
                <div className="w-1/2 aspect-square rounded-lg">
                    <div className="h-full w-full flex flex-col justify-between gap-1">
                        <div className="w-full h-3/6 bg-slate-300 rounded-lg animate-hightLight"></div>
                        <div className="w-full h-1/6 bg-slate-300 rounded-lg animate-hightLight"></div>
                        <div className="w-full h-2/6 bg-slate-300 rounded-lg animate-hightLight"></div>
                    </div>
                </div>
            </div>
        );
    return (
        <div>
            {productInfo.isLimitCap && (
                <div className="flex gap-2 p-1 bg-primary-300 mb-1 rounded-md">
                    <p className="flex gap-1 items-center">
                        <FontAwesomeIcon icon={faWarning} />
                        <span>{`Sản phẩm giới hạn hoa hồng!`}</span>
                    </p>
                    <span>{`Tối đa ${formattedMoney.format(
                        productInfo.cap
                    )} mỗi đơn!`}</span>
                </div>
            )}
            <div className="flex text-xs md:text-base gap-3 relative w-full bg-white shadow-md rounded-lg h-full">
                <div className="flex justify-center flex-1">
                    <div className="w-full aspect-square overflow-hidden rounded-lg border border-black">
                        <img
                            className="h-full w-full object-cover"
                            src={imageUrl}
                            alt="quan-ao"
                        />
                    </div>
                </div>
                <div className="flex-1 flex flex-col justify-between">
                    <div className="flex flex-col p-1 justify-between">
                        <span className="overflow-hidden text-ellipsis line-clamp-3 font-semibold">
                            {productName.toUpperCase()}
                        </span>
                        <div className="flex flex-col justify-between">
                            <span className="overflow-hidden text-ellipsis line-clamp-1">
                                {shopName}
                            </span>
                            {sales && (
                                <span className="whitespace-nowrap">
                                    Lượt bán:{' '}
                                    <strong>{`${convertToK(sales)}`}</strong>
                                </span>
                            )}
                        </div>
                        <span className="text-base font-bold leading-5">
                            {formattedMoney.format(price)}
                        </span>
                        <span
                            className={`${
                                buyBtnTextColor || 'text-primary-600'
                            }`}
                        >
                            Hoàn tiền đến:
                            <strong className="font-bold text-base leading-3">{` ${formattedMoney.format(
                                commission
                            )}`}</strong>
                        </span>
                    </div>
                    {!onlyInfo && (
                        <div className="flex flex-col gap-1 justify-end">
                            <button
                                onClick={handleSubmit}
                                className={`p-2 md:p-3 ${
                                    buyBtnBgColor || 'bg-primary-600'
                                } ${
                                    cashBackTextColor || 'text-white'
                                } rounded bottom-2 right-2 w-full font-bold`}
                            >
                                MUA NGAY
                            </button>
                            {/* COPY LINK */}
                            {platform !== 'tiktok' && (
                                <div className="relative rounded overflow-hidden">
                                    {/* <TopToast
                                        copied={copied}
                                        setCopied={setCopied}
                                    />
                                    <button
                                        onClick={onCopy}
                                        className="p-2 md:p-3 rounded-md w-full h-full border  bg-gray-500 text-white"
                                    >
                                        {onCopyLink ? (
                                            <span>Đang lấy...</span>
                                        ) : link ? (
                                            <div className="flex justify-center items-center gap-1 ">
                                                <span>Đã sao chép</span>
                                                <FontAwesomeIcon
                                                    icon={faCoins}
                                                />
                                            </div>
                                        ) : (
                                            <span>
                                                THƯỞNG THÊM HOA HỒNG{' '}
                                                <FontAwesomeIcon
                                                    icon={faCoins}
                                                />
                                            </span>
                                        )}
                                    </button> */}
                                    <button
                                        onClick={() => setOpenModalEarn(true)}
                                        className="p-2 md:p-3 rounded-md w-full h-full border  bg-gray-500 text-white"
                                    >
                                        <span>
                                            THƯỞNG THÊM HOA HỒNG{' '}
                                            <FontAwesomeIcon icon={faCoins} />
                                        </span>
                                    </button>
                                </div>
                            )}
                        </div>
                    )}
                </div>
                <Modal isOpen={onGetLink}>
                    <RedirectPopup platform={platform} />
                </Modal>
                <Modal isOpen={openModal}>
                    <JoinGroup onClose={() => setOpenModal(false)} />
                </Modal>
                <Modal isOpen={openModalEarn}>
                    <EarnCommission
                        setOpenModal={setOpenModalEarn}
                        platform={platform}
                        link={productLink}
                        productInfo={productInfo}
                    />
                </Modal>
            </div>
        </div>
    );
}

export default ProductCardMain;

export { clickAffLink };
